<template>
  <div class="col-12 col-md-10 offset-md-1 offset-lg-2 mx-auto">
    <alert v-if="sessionsLoading" class="light-shadow" />
    <div v-else>
      <div
        class="
          card
          theme-card
          overflow-auto
          light-card
          mobile-tablet-no-card
          border-0
        "
      >
        <alert class="my-3 mx-3" :hideLoader="true" v-if="sessions.length == 0">
          No Sessions Found.
          <router-link :to="{ name: 'patients.okaya.checkIn' }"
            >Add a new session here</router-link
          >
        </alert>
        <div
          class="
            card
            theme-card
            mb-0
            mobile-tablet-no-card
            p-2 p-md-0
            border-0
            table-container
          "
          v-if="sessions.length > 0"
        >
          <table
            class="table table-striped mb-0 responsive-mobile-tablet-table"
          >
            <thead>
              <tr>
                <th>ID</th>
                <th @click="showMHCIGuideDialog" class="pointer-cursor">
                  MHCI <icon type="info-circle" />
                </th>
                <th>Created At</th>
                <th class="w-25 text-center">Actions</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(session, index) in sessions" :key="index">
                <td>
                  <b class="mobile-tablet-only mr-2">ID: </b>
                  {{ session.id }}
                </td>
                <td>
                  <b class="mobile-tablet-only mr-2">MHCI: </b>
                  {{ (session.mhci || 0).toFixed(1) }}
                </td>
                <td>
                  <b class="mobile-tablet-only mr-2">Created At: </b>
                  {{ prettyDate(session.created_at) }}
                </td>
                <td class="td-actions text-center">
                  <button
                    title="View Session"
                    class="btn btn-outline-blue mx-1 my-1"
                    @click="viewSession(session)"
                  >
                    <span> <icon type="eye" /> View Details</span>
                  </button>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
    <mhci-guide-modal />
    <modal
      class="session-info-modal"
      name="session-info-modal"
      transition="pop-out"
      width="96%"
      height="96%"
    >
      <div class="modal-container">
        <div
          class="modal-title border-bottom text-secondary position-relative p-3"
        >
          Okaya Session Details
          <button class="close pr-2" @click="closeSessionInfoModal">
            &times;
          </button>
        </div>

        <div class="modal-body">
          <session-info :info="selectedSession" />
        </div>
      </div>
    </modal>
    <progress-chart
      :sessions="sessions"
      :loading="sessionsLoading"
      :patient="patient"
    />
  </div>
</template>
<script>
import dayjs from "dayjs";
import MhciGuideModal from "./MhciGuideModal.vue";
import SessionInfo from "./sessionInfo.vue";
import ProgressChart from "./ProgressChart.vue";
import { mapActions, mapState } from "vuex";

export default {
  components: {
    MhciGuideModal,
    SessionInfo,
    ProgressChart,
  },
  data() {
    return {
      selectedSession: null,
    };
  },
  mounted() {
    this.getSessions({
      user_id: this.patient.user_id,
    });
  },
  computed: {
    ...mapState({
      user: (state) => state.auth.user,
      patient: (state) => state.patients.patient,
      sessions: (state) => state.okaya.sessions.data,
      sessionsLoading: (state) => state.okaya.sessions.loading,
    }),
  },
  methods: {
    ...mapActions({
      getSessions: "okaya/getSessions",
      getSessionInfo: "okaya/getSession",
    }),
    viewSession(session) {
      this.selectedSession = null;
      this.$modal.show("session-info-modal");
      this.getSessionInfo(session).then((res) => {
        if (!res) {
          this.closeSessionInfoModal();
        } else {
          res.mhci = session.mhci;
          this.selectedSession = res;
        }
      });
    },
    closeSessionInfoModal: function () {
      this.$modal.hide("session-info-modal");
    },
    showMHCIGuideDialog: function () {
      this.$modal.show("mhci-guide-modal");
    },
    prettyDate: function (date, onlyDate = false) {
      if (onlyDate) {
        return dayjs(date).format("MMM DD, YYYY");
      } else {
        return dayjs(date).format("MMM DD, YYYY  hh:mm A");
      }
    },
  },
};
</script>
<style lang="scss" scoped>
table {
  font-size: 0.9rem;
}

.session-info-modal {
  .v--modal {
    max-width: 96%;
  }
  .modal-title {
    padding-top: 10px;
    padding-bottom: 10px;
    font-size: 26px;
    background-color: rgba(126, 126, 126, 0.1);
  }
  .close {
    color: black !important;
    font-size: 1.5em;
  }
  .modal-body {
    padding: 24px;
    height: calc(100% - 65px);
    overflow: auto;
    position: absolute;
    top: 65px;
    left: 0;
    right: 0;
  }
}
</style>
