<template>
  <modal
    name="mhci-guide-modal"
    transition="pop-out"
    class="mhci-guide-modal"
    :width="520"
    :height="410"
  >
    <div class="position-relative modal-outer-container">
      <div class="modal-header border-bottom">
        <div>MHCI Description</div>
        <button class="btn p-2 m-2 border-0" @click="closeCustomDateModal">
          <i class="fas fa-times fa-lg"></i>
        </button>
      </div>
      <div class="body-inner-container">
        <div class="card-body">
          <div class="row px-3">
            <div class="col-12 col-md-6 d-flex align-content-center p-2">
              <img
                class="mr-2"
                src="@/assets/img/okaya/very-Healthy.png"
                alt="Very Healthy"
                width="55"
                height="55"
              />
              <span>
                Very Healthy 80-100 <br />
                65-99th percentile
              </span>
            </div>
            <div class="col-12 col-md-6 d-flex align-content-center p-2">
              <img
                class="mr-2"
                src="@/assets/img/okaya/sustainable.png"
                alt="Sustainable"
                width="55"
                height="55"
              />
              Sustainable 60-80 <br />
              35-64th percentile
            </div>
            <div class="col-12 col-md-6 d-flex align-content-center p-2">
              <img
                class="mr-2"
                src="@/assets/img/okaya/moderate.png"
                alt="Moderate"
                width="55"
                height="55"
              />
              Moderate 45-60 <br />
              20-34th percentile
            </div>
            <div class="col-12 col-md-6 d-flex align-content-center p-2">
              <img
                class="mr-2"
                src="@/assets/img/okaya/needs-Support.png"
                alt="Needs Support"
                width="55"
                height="55"
              />
              Needs Support 0-45 <br />
              0-19th percentile
            </div>
          </div>
          <div class="mt-3 px-3">
            The MHCI (Mental Health Climate Index) is our best estimate of your
            mental wellbeing.
          </div>
          <div class="small mt-3 px-3">
            Sometimes we are not able to analyze your session correctly. Some of
            the reasons can include:
            <ul>
              <li>You did not speak long enough</li>
              <li>We could not see your face clearly</li>
              <li>Your network was unstable</li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </modal>
</template>
<script>
export default {
  name: "MhciGuideModal",
  methods: {
    closeCustomDateModal() {
      this.$modal.hide("mhci-guide-modal");
    },
  },
};
</script>
<style lang="scss" scoped>
.card-body {
  overflow-y: auto;
}
</style>
