<template>
  <div class="card-container light-shadow card theme-card">
    <div class="title mb-3">{{ patient.name }} Progress</div>
    <div>
      <alert v-if="loading" />
      <alert v-if="noChartData && !loading" :hideLoader="true">
        No data to show
      </alert>

      <div id="progressChart" v-show="!noChartData"></div>
    </div>
  </div>
</template>
<script>
import ApexCharts from "apexcharts";
import { progressChartConfig } from "./charts";

export default {
  props: ["sessions", "patient", "loading"],
  data() {
    return {
      noChartData: false,
      chartRef: null,
      progressChartConfig,
    };
  },
  updated() {
    this.drawChart();
  },
  beforeDestroy() {
    if (this.chartRef) {
      this.chartRef.destroy();
    }
  },
  methods: {
    drawChart: function () {
      if (this.sessions.length === 0) {
        this.noChartData = true;
        return;
      }
      this.noChartData = false;
      if (this.chartRef) {
        this.chartRef.destroy();
      }

      const seriesData = this.sessions.map((session) => {
        return {
          x: session.created_at,
          y: session.mhci,
        };
      });
      this.progressChartConfig.series[0].data = seriesData;
      if (document.querySelector("#progressChart")) {
        this.chartRef = new ApexCharts(
          document.querySelector("#progressChart"),
          this.progressChartConfig
        );
        this.chartRef.render();
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.card-container {
  padding: 36px 32px;
}
.title {
  font-size: 28px;
  font-weight: 500;
  @media (max-width: 767px) {
    font-size: 18px;
  }
}
</style>
