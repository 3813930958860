<template>
  <div class="">
    <div v-if="!info" class="col-12">
      <alert class="my-2 shadow-card" />
    </div>
    <div v-if="info" class="shadow-card d-flex flex-wrap">
      <div class="title pt-4 pb-md-4 col-12 col-md-4">MHCI</div>
      <div class="value pt-2 pt-md-4 pb-md-4 col-12 col-md-8">
        <p>
          Based on attributes calculated from your video, audio, and discussion.
        </p>
        <div class="d-flex row m-0 align-items-center mt-4">
          <div>
            <p class="text-primary h1 font-weight-normal pt-1 pb-1">
              {{ (info.mhci || 0).toFixed(1) }}
            </p>
            <span
              class="pointer-cursor text-secondary small"
              @click="showMHCIGuideDialog"
              >Learn more <i class="fas fa-long-arrow-alt-right"></i
            ></span>
          </div>
          <div class="text-center ml-5 pl-5">
            <img :src="mhciImg.src" class="mhci-img" />
            <p class="mt-2 font-weight-bold pre-wrap">{{ mhciImg.alt }}</p>
          </div>
        </div>
      </div>
      <template v-if="info.subscores">
        <hr class="col-11 my-0" />
        <div class="title pt-4 pb-md-4 col-12 col-md-4">Subscores</div>
        <div class="value pt-2 pt-md-4 pb-md-4 col-12 col-md-8">
          <p>Vision Subscore: {{ info.subscores.vision.toFixed(1) }}</p>
          <p>Transcript Subscore: {{ info.subscores.transcript.toFixed(1) }}</p>
        </div>
      </template>
      <template v-if="info.video">
        <hr class="col-11 my-0" />
        <div class="title pt-4 pb-md-4 col-12 col-md-4">Video</div>
        <div class="value pt-2 pt-md-4 pb-md-4 col-12 col-md-8">
          <div class="loading-text" v-if="!videoIsReady">
            <span>Video being loading...</span>
          </div>
          <video
            style="
              width: 24rem;
              height: 24rem;
              max-width: 100%;
              max-height: 100%;
              box-shadow: rgba(0, 0, 0, 0.35) 0px 0px 1px;
            "
            ref="videoPlayer"
            class="video-player vjs-big-play-centered"
            playsinline="true"
            controlslist="nodownload"
            controls
            @canplay="onPlayerCanplay($event)"
          >
            <source :src="info.video" type="video/mp4" />
          </video>
        </div>
      </template>
      <template v-if="info.transcript">
        <hr class="col-11 my-0" />
        <div class="title pt-4 pb-md-4 col-12 col-md-4">Transcript</div>
        <div class="value pt-2 pt-md-4 pb-md-4 col-12 col-md-8">
          <p v-for="(item, index) in info.transcript" :key="index">
            <span class="text-primary font-weight-bold pr-2"
              >{{ item.role == "user" ? "Me" : "AI" }}-</span
            >
            {{ item.content }}
          </p>
        </div>
      </template>
      <template>
        <hr class="col-11 my-0" />
        <div class="title pt-4 pb-md-4 col-12 col-md-4">Suggestions</div>
        <div class="value pt-2 pt-md-4 pb-md-4 col-12 col-md-8">
          <p
            v-for="(item, index) in suggestions"
            :key="index"
            class="mb-3"
            v-html="item"
          ></p>
        </div>
      </template>
    </div>
    <mhci-guide-modal />
  </div>
</template>
<script>
import MhciGuideModal from "./MhciGuideModal.vue";

export default {
  name: "sessionInfo",
  components: {
    MhciGuideModal,
  },
  data() {
    return {
      videoIsReady: false,
      suggestions: [
        "Remember, it's just stigma that often stands in the way of seeking help for mental health issues.<br>The sooner you reach out, the simpler it can be to navigate and overcome these challenges.",
        "You're not alone, and there's always a way forward. If you or someone you know is experiencing a mental health crisis, immediate support is available.",
        "In the U.S., call the National Suicide Prevention Lifeline at 1-800-273-TALK (1-800-273-8255).",
        "In the UK, contact Samaritans at 116 123. Both lines are available 24/7, offering confidential assistance.",
        "Don't hesitate to make that call – it's a brave and important step towards well-being.",
        "Note: These are not medical recommendations.",
      ],
    };
  },
  props: ["info"],
  methods: {
    showMHCIGuideDialog: function () {
      this.$modal.show("mhci-guide-modal");
    },
    onPlayerCanplay() {
      this.videoIsReady = true;
    },
  },
  computed: {
    mhciImg() {
      const score = parseInt(String(this.info.mhci || 0)).toFixed(1);
      if (score < 45) {
        return {
          src: require("@/assets/img/okaya/needs-Support.png"),
          alt: "Needs Support 0-45\n0-19th percentile",
        };
      } else if (score >= 45 && score < 60) {
        return {
          src: require("@/assets/img/okaya/moderate.png"),
          alt: "Moderate 45-60\n20-34th percentile",
        };
      } else if (score >= 60 && score < 80) {
        return {
          src: require("@/assets/img/okaya/sustainable.png"),
          alt: "Sustainable 60-80\n35-64th percentile",
        };
      } else if (score >= 80 && score < 100) {
        return {
          src: require("@/assets/img/okaya/very-Healthy.png"),
          alt: "Very Healthy 80-100\n65-99th percentile",
        };
      }
      return {};
    },
  },
};
</script>
<style lang="scss" scoped>
.title {
  font-weight: bold;
}
.mhci-img {
  width: 100px;
}
.pre-wrap {
  white-space: pre-wrap;
}
</style>
