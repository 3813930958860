import dayjs from "dayjs";

export const progressChartConfig = {
  series: [
    {
      name: "MHCI",
      data: [],
    },
  ],
  chart: {
    type: "line",
    height: 350,
    foreColor: "var(--main-text-color)",
    fontFamily: "'Cerebri Sans', sans-serif",
    zoom: {
      type: "x",
      enabled: true,
      autoScaleYaxis: true,
    },
    toolbar: {
      autoSelected: "zoom",
    },
  },
  dataLabels: {
    enabled: true,
    style: {
      fontFamily: "'Cerebri Sans', sans-serif",
      colors: ["#000"],
    },
  },
  markers: {
    size: 0,
    colors: ["var(--theme-color,#2c7be5)"],
    strokeColor: "var(--theme-color,#2c7be5)",
  },
  title: {
    text: "",
  },
  stroke: {
    colors: ["var(--theme-color,#2c7be5)"],
  },
  yaxis: {
    labels: {
      formatter: function (value) {
        return value.toFixed(0);
      },
    },
    min: 0,
    max: 100,
  },
  xaxis: {
    tooltip: {
      enabled: false,
      intersect: false,
    },
    labels: {
      hideOverlappingLabels: true,
      showDuplicates: true,
      formatter: function (value) {
        return dayjs(value).format("YYYY-MM-DD");
      },
    },
  },
  tooltip: {
    shared: false,
    intersect: false,
    marker: {
      show: true,
      fillColors: ["var(--theme-color,#2c7be5)"],
    },
    style: {
      fontSize: "14px",
    },
    y: {
      formatter: function (value) {
        return value.toFixed(2);
      },
    },
    x: {
      formatter: function (_, { seriesIndex, dataPointIndex, w }) {
        const valueToFormat =
          w.config.series[seriesIndex].data[dataPointIndex].x;
        return dayjs(valueToFormat).format("YYYY-MM-DD h:mm A");
      },
    },
  },
  annotations: {
    yaxis: [
      {
        y: 0,
        y2: 45,
        borderColor: "#ff6666",
        fillColor: "#ff6666",
        opacity: 0.2,
      },
      {
        y: 45,
        y2: 60,
        borderColor: "#ffff00",
        fillColor: "#ffff00",
        opacity: 0.2,
      },
      {
        y: 60,
        y2: 80,
        borderColor: "#66ff66",
        fillColor: "#66ff66",
        opacity: 0.2,
      },
      {
        y: 80,
        y2: 100,
        borderColor: "#00ff00",
        fillColor: "#00ff00",
        opacity: 0.2,
      },
    ],
  },
};
